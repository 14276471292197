import React from "react";
import "./assortiment.scss";
import Image from "../../assets/images/fish-foto.png";
import { motion } from "framer-motion";
import useWindowSize from "../useWindowSize";

function Assortiment() {
  const animate = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", bounce: 0.3, duration: 1 },
    },
  };

  const { width } = useWindowSize();

  function getViewportAmount(width) {
    if (width <= 768) {
      return 0.1;
    } else {
      return 0.3;
    }
  }

  return (
    <motion.section
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: getViewportAmount(width) }}
      variants={animate}
      transition={{ staggerChildren: 1 }}
      id="Assortiment"
    >
      <div>
        <div className="assortiment-container">
          <div className="assortiment-wrap">
            <motion.div className="wrap">
              <motion.h1 className="assortiment-title">
                Ons Assortiment
              </motion.h1>
              <motion.div variants={animate} className="fish-image">
                <motion.img src={Image} loading="lazy" alt="fish-image" />
              </motion.div>
              <motion.div variants={animate} className="assortiment">
                <div className="verse-vis kader">
                  <div className="title-vis vers">
                    <motion.h1 variants={animate}>Verse Vis</motion.h1>
                  </div>
                  <div className="text">
                    <p>
                      Bij ons vind je verse vis van topkwaliteit, waaronder
                      zalm, kabeljauw, makreel, etc.. <br /> <br /> Al onze vis
                      is beschikbaar binnen 24 uur na de vangst.
                    </p>
                  </div>
                </div>
                <div className="bevroren-vis kader">
                  <div className="title-vis bevroren">
                    <h1>Bevroren Vis</h1>
                  </div>
                  <div className="text">
                    <p>
                      Wil je langer genieten van heerlijke visgerechten? <br />{" "}
                      <br /> Onze diepvries vis zoals scampi's en gamba's, wordt
                      vlak na de visvangst ingevroren om de optimale smaak te
                      behouden.
                    </p>
                  </div>
                </div>
                <div className="opgelegde-vis kader">
                  <div className="title-vis opgelegd">
                    <h1>Opgelegde Vis</h1>
                  </div>
                  <div className="text">
                    <p>
                      Fewa Fish biedt een ruime selectie aan opgelegde
                      vissoorten van de hoogste kwaliteit. <br /> <br /> Geniet
                      van de authentieke smaken van sashimi en meer.
                    </p>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default Assortiment;
