import React from "react";
import "./credits.scss";

export default function Credits() {
  return (
    <div className="credits">
      <div className="wrapper">
        <div className="container">
          <div className="copyright">
            <p style={{ width: "200px" }}>Copyright 2023 © Fewa Fish</p>
          </div>
          <div className="made">
            <p style={{ width: "150px" }} className="cursor">
              Created by{" "}
              <a
                href="https://somrad-sharma.vercel.app/"
                target="_blank"
                className="author"
              >
                Somrad Sharma
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
