import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import Hero from "./components/hero/Hero";
import OverOns from "./components/over-ons/OverOns";
import Assortiment from "./components/assortiment/Assortiment";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Credits from "./components/credits/Credits";
import Navbar from "./components/nav/Navbar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Navbar />
    <Hero />
    <OverOns />
    <Assortiment />
    <Contact />
    <Footer />
    <Credits />
  </>
);
