import React from "react";
import "./hero.scss";
import { Link } from "react-scroll";
import Image from "../../assets/images/hero-image.png";
import { motion } from "framer-motion";

function Hero() {

  const animate = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", bounce: 0.3, duration: 1 },
    },
  };

  return (
    <motion.section
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: true, amount: 0.3 }}
      variants={animate}
      transition={{ staggerChildren: 1 }}
      id="Home"
    >
      <div>
        <div className="hero-container">
          <div className="hero-wrap">
            <div className="wrap">
              <motion.div variants={animate} className="image">
                <img src={Image} alt="fish-vector-image" />
              </motion.div>
              <motion.div variants={animate} className="text">
                <h1>Fewa Fish</h1>
                <div className="bio">
                  <h3>
                    Fewa Fish is de plek voor de beste vis en zeevruchten in
                    Leuven. Ontdek onze dagelijks verse aanvoer en proef de
                    kwaliteit!
                  </h3>
                </div>
                <Link
                  to="Contact"
                  smooth={true}
                  duration={100}
                  offset={-100}
                >
                  <button>
                    <h3>Bestellen</h3>
                  </button>
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default Hero;
