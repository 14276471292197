import React from "react";
import Logo from "../../assets/logos/full-logo.png";
import Facebook from "../../assets/icons/fb.png";
import TikTok from "../../assets/icons/tiktok.png";
import WhatsApp from "../../assets/icons/whatsapp.png";
import { Link } from "react-scroll";
import "./footer.scss";

function Footer() {
  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer">
      <div className="footer-logo">
        <Link to="" onClick={handleLogoClick} className="hover-effect">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <div className="footer-column">
        <Link
          className="footer-hover"
          to="Home"
          smooth={true}
          duration={100}
          offset={-50}
          activeClass="active"
          onClick={handleLogoClick}
        >
          <h4>Home</h4>
        </Link>
        <ul>
          <Link
            className="footer-hover"
            to="OverOns"
            smooth={true}
            offset={-150}
            activeClass="active"
            duration={100}
          >
            <li>Over Ons</li>
          </Link>
          <Link
            className="footer-hover"
            to="Assortiment"
            smooth={true}
            offset={-150}
            activeClass="active"
            duration={100}
          >
            <li>Ons Assortiment</li>
          </Link>
          <Link
            className="footer-hover"
            to="Contact"
            smooth={true}
            offset={-150}
            activeClass="active"
            duration={100}
          >
            <li>Contact</li>
          </Link>
        </ul>
      </div>
      <div className="footer-column days">
        <div className="dag">
          <h4>Dagen</h4>
          <ul>
            <li>Maandag</li>
            <li>Dinsdag t.em Zondag</li>
          </ul>
        </div>
        <div className="uren">
          <h4>Uren</h4>
          <ul>
            <li>
              <b>Gesloten</b>
            </li>
            <li>
              <b>9u.00 - 19u.00</b>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-column contact">
        <h4>Contact</h4>
        <ul>
          <li>Brusselsestraat 124, 3000 Leuven</li>
          <li>Tel. 016 19 20 05 </li>
          <li>BTW BE. 0655820364 </li>
        </ul>
        <div className="contact">
          <div className="social-icons">
            <a href="https://www.facebook.com/fewafish" target="_blank">
              <img
                src={Facebook}
                loading="lazy"
                alt="facebook-icon"
                className="hover-effect"
              />
            </a>
            <a href="https://www.tiktok.com/@fewafish?lang=en" target="_blank">
              <img
                src={TikTok}
                loading="lazy"
                alt="tiktok-icon"
                className="hover-effect"
              />
            </a>
            <a
              href="https://wa.me/32488562450?text=Hallo%2C%20Ik%20heb%20een%20vraagje."
              target="_blank"
            >
              <img
                src={WhatsApp}
                loading="lazy"
                alt="whatsapp-icon"
                className="hover-effect"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
