import React, { useState, useRef, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../../assets/logos/logo-circle.png";
import LogoScrolled from "../../assets/logos/logo-circle-grey.png";
import { Link } from "react-scroll";
import "./Navbar.scss";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navBackground, setNavBackground] = useState("transparent");
  const [navTextColor, setNavTextColor] = useState("#31568a");
  const [logoSrc, setLogoSrc] = useState(Logo);
  const navRef = useRef();
  const logoRef = useRef();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    navRef.current.classList.toggle("responsive_nav");
    document.body.style.overflow = isMenuOpen ? "auto" : "hidden";
  };

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    if (window.innerWidth >= 1024) {
      setNavBackground(isScrolled ? "#23A7D7" : "#e9e9e9");
      setNavTextColor(isScrolled ? "#e9e9e9" : "#31568a");
      setLogoSrc(isScrolled ? LogoScrolled : Logo);
    } else {
      setNavBackground(isScrolled ? "#23A7D7" : "transparent");
      setNavTextColor(isScrolled ? "#e9e9e9" : "#31568a");
      setLogoSrc(isScrolled ? LogoScrolled : Logo);
    }
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    navRef.current.classList.remove("responsive_nav");
    document.body.style.overflow = "auto";
  };

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.style.overflow = "auto";
    };
  }, []);

  const NavLinks = ({ textColor }) => (
    <>
      <Link
        className="home-link"
        style={{ color: textColor }}
        to="Home"
        smooth={true}
        duration={400}
        offset={-50}
        activeClass="active"
        onClick={handleLinkClick}
      >
        Home
      </Link>

      <Link
        className="nav-link"
        style={{ color: textColor }}
        to="OverOns"
        smooth={true}
        offset={-150}
        activeClass="active"
        duration={400}
        onClick={handleLinkClick}
      >
        Over Ons
      </Link>
      <Link
        className="nav-link"
        style={{ color: textColor }}
        to="Assortiment"
        smooth={true}
        offset={-50}
        activeClass="active"
        duration={400}
        onClick={handleLinkClick}
      >
        Ons Assortiment
      </Link>
      <Link
        className="nav-link"
        style={{ color: textColor }}
        to="Contact"
        smooth={true}
        offset={-100}
        duration={400}
        onClick={handleLinkClick}
      >
        Contact
      </Link>
    </>
  );

  return (
    <header style={{ backgroundColor: navBackground }}>
      <Link to="" className="logo-link" onClick={handleLogoClick}>
        <img
          ref={logoRef}
          className="logo"
          src={logoSrc}
          alt="fewa-fish-logo"
          offset={-50}
          duration={100}
        />
      </Link>

      <nav className="nav-list" ref={navRef}>
        <NavLinks textColor={navTextColor} />
        <button className="nav-btn nav-close-btn" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes color="#E9E9E9" /> : <FaBars />}
        </button>
      </nav>
      <button className="nav-btn" onClick={toggleMenu}>
        <FaBars color={navBackground === "#23A7D7" ? "#E9E9E9" : "#31568A"} />
      </button>
    </header>
  );
};

export default Navbar;
