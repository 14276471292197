import React from "react";
import "./overons.scss";
import Image from "../../assets/images/store.jpg";
import useWindowSize from "../useWindowSize";
import { motion } from "framer-motion";

function OverOns() {
  const { width } = useWindowSize();

  function getViewportAmount(width) {
    if (width <= 768) {
      return 0.4;
    } else {
      return 0.8;
    }
  }

  const animate = {
    offscreen: { x: 100, opacity: 0 },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", bounce: 0.3, duration: 1 },
    },
  };

  return (
    <section id="OverOns">
      <div className="overons">
        <div className="overons-container">
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: getViewportAmount(width) }}
            variants={animate}
            transition={{ staggerChildren: 1 }}
            className="overons-wrap"
          >
            <div className="wrap">
              <motion.div variants={animate} className="image">
                <img src={Image} loading="lazy" alt="over-ons" />
              </motion.div>
              <motion.div variants={animate} className="text">
                <h1>Over Ons</h1>
                <div className="bio">
                  <h3>
                    Bij Fewa Fish in Leuven vind je topkwaliteit vis en
                    zeevruchten. Als familiezaak zijn we trots op onze dagelijks
                    verse aanvoer en persoonlijke service. <br /> <br /> Ontdek
                    ons ruim assortiment aan verse vis, schaal- en schelpdieren
                    en andere heerlijkheden. Kom proeven en laat je verrassen!
                  </h3>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default OverOns;
