import React from "react";
import "./contact.scss";
import { motion } from "framer-motion";
import Telephone from "../../assets/icons/tele.png";
import Mail from "../../assets/icons/mail.png";
import Facebook from "../../assets/icons/fb.png";
import TikTok from "../../assets/icons/tiktok.png";
import WhatsApp from "../../assets/icons/whatsapp.png";
import useWindowSize from "../useWindowSize";

function Contact() {
  const animate = {
    offscreen: { x: 100, opacity: 0 },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", bounce: 0.3, duration: 1 },
    },
  };

  const { width } = useWindowSize();

  function getViewportAmount(width) {
    if (width <= 768) {
      return 0.1;
    } else {
      return 0.3;
    }
  }

  return (
    <section id="Contact">
      <div className="contact-container">
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: getViewportAmount(width) }}
          variants={animate}
          transition={{ staggerChildren: 1 }}
        >
          <motion.div variants={animate}>
            <h1 className="contact-title">Contacteer ons</h1>
            <div className="contact-description">
              <p>
                Heeft u vragen over ons assortiment, wilt u een bestelling
                plaatsen of bent u op zoek naar een specifieke vis? <br />{" "}
                Aarzel dan niet om contact met ons op te nemen. Of kom langs in
                onze vishandel om onze heerlijke vis te ontdekken en te proeven.{" "}
                <br /> We helpen je graag verder!
              </p>
            </div>
          </motion.div>
          <motion.div variants={animate} className="contact-details">
            <div className="openingsuren box">
              <h2>Openingsuren</h2>
              <div className="opening">
                <div className="dag">
                  <p>Maandag</p>
                  <p id="dinsdag">Dinsdag t.e.m Zondag</p>
                </div>
                <div className="uren">
                  <p>
                    <b>Gesloten</b>
                  </p>
                  <p id="uur">
                    <b>9u.00 - 19u.00</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="details box">
              <h2>Details</h2>
              <div className="contact-icons">
                <div className="telephone">
                  <a href="tel:016192005">
                    <img
                      src={Telephone}
                      alt="telephone-icon"
                      className="icon-link"
                      loading="lazy"
                    />
                    <div className="icon-text">
                      <p className="icon-value">016 19 20 05</p>
                    </div>
                  </a>
                </div>
                <div className="mail">
                  <a href="mailto:info@fewafish.be">
                    <img
                      src={Mail}
                      alt="mail-icon"
                      loading="lazy"
                      className="icon-link"
                    />
                    <div className="icon-text">
                      <p className="icon-value">info@fewafish.be</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="social-icons">
                <a href="https://www.facebook.com/fewafish" target="_blank">
                  <img
                    src={Facebook}
                    loading="lazy"
                    alt="facebook-icon"
                    className="icon-link"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@fewafish?lang=en"
                  target="_blank"
                >
                  <img
                    src={TikTok}
                    loading="lazy"
                    alt="tiktok-icon"
                    className="icon-link"
                  />
                </a>
                <a
                  href="https://wa.me/32488562450?text=Hallo%2C%20Ik%20heb%20een%20vraagje."
                  target="_blank"
                >
                  <img
                    src={WhatsApp}
                    loading="lazy"
                    alt="whatsapp-icon"
                    className="icon-link"
                  />
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div variants={animate} className="google">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.3272192437344!2d4.692528815955257!3d50.88065386415348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c161e7662f6225%3A0xf10dd17c31cb069f!2sFewa%20Fish%20viswinkel!5e0!3m2!1sen!2sbe!4v1678708490433!5m2!1sen!2sbe"
              width="800"
              height="600"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen=""
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default Contact;
